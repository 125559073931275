.order-Video {
  background-color: #d9e7e3;
  width: 96%;
  display: flex;
  align-items: center;
  padding: 11px;
  justify-content: center;
  margin: 0 auto;
  border-radius: 3px;
  font-size: 15px;
  border: 2px solid #ccccccd1;
  margin-top: 11px;
}
.order-display {
  margin: 12px auto;
  width: 98%;
  background: "azure";
  border: "2px solid #ccc";
}
.colorRed {
  background-color: rgba(255, 0, 0, 0.619);
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.fillter-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  overflow: auto;
}
.filter-2nd {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #eeefff;
  border-bottom: 1px solid #ccc;
  padding-top: 8px;
  padding-bottom: 8px;
}
.datePicker {
  display: flex;
  background: #d4dada7d;
  padding: 5px;
  border-radius: 5px;
}
.datePicker p {
  margin: 0px;
  padding-right: 5px;
}
.date-date-Picker {
  display: flex;
  background: #d4dada7d;
  border-radius: 5px;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
}
.date-date-Picker p {
  margin: 0px;
}

.search-date {
  height: 23px;
  width: 55px;
  cursor: pointer;
  padding-bottom: 8px;
}
.when-select {
  background-color: #eeefff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 13px;
  transition: all 10s ease-out;
}
.when-select-state {
  background-color: steelblue;
  padding: 6px;
  border-radius: 6px;
  color: aliceblue;
  cursor: pointer;
  font-size: small;
}
.sort-day {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sort-day-child {
  padding: 9px;
  background-color: #efefef;
  margin: 3px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
.filter-status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-status ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -21px;
  flex-wrap: wrap;
}

.filter-status ul li {
  list-style: none;
  padding: 5px;
  background-color: #efefef;
  margin: 2px;
  border-radius: 7px;
  cursor: pointer;
  font-size: 11px;
}
.filter-status ul li.active {
  background-color: #2c974b;
  color: #efefef;
}
.filter-status ul li.active:hover {
  background-color: #17ab41;
  color: #efefef;
}

.filter-status ul li:hover {
  background-color: #f5e3e3;
}
.advance-report {
  background-color: #d9e7e3;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  font-family: initial;
  color: blue;
}
.advance-report:hover {
  background-color: #cbede3;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  text-decoration: underline;
  color: brown;
}
.searchin {
  padding: 10px;
}
.delete {
  background-color: #ff0000c9;
}

.delete:hover {
  background-color: red;
}
.pagination-order {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 6px;
  width: 35%;
  margin: 15px auto;
  border-radius: 50px;
  border: 1px solid #ccc;
}

.Show-Order {
  width: 90%;
  height: 100%;
  margin: 0 auto;
}
.Show-Order-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .filter-2nd {
    flex-direction: column;
  }
  .datePicker {
    margin: 10px;
  }
  .date-date-Picker {
    flex-direction: column;
    align-items: center;
    padding: 16px;
    margin-bottom: 7px;
  }
  .search-date {
    margin-top: 10px;
  }
  .pagination-order {
    width: 95%;
  }
  .order-Video {
    width: 92%;
  }
  .sort-day {
    margin-bottom: 10px;
    margin-top: 9px;
  }
  .fillter-main {
    flex-direction: column;
  }
  .search {
    margin-bottom: 20px;
  }
}
