/* Features.css */

/* Container styles */
.features-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

/* Heading styles */
.features-heading {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #333;
}

/* Grid layout for feature cards */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

/* Card styles */
.features-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.features-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Icon, title, and text styles for each card */
.features-card-icon {
  font-size: 48px;
  margin-bottom: 15px;
  color: #ffbe2e;
}

.features-card-title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.features-card-text {
  font-size: 1rem;
  color: #555;
}

/* Custom list styles */
.features-card-list {
  list-style: none; /* Remove default bullet */
  padding-left: 0;
  text-align: left;
}

.features-card-list-item {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 8px;
}

/* Create a custom colored bullet using a pseudo-element */
.features-card-list-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 8px;
  width: 8px;
  background-color: #0ad995;
  border-radius: 50%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .features-card-icon {
    font-size: 36px;
  }
  .features-card-title {
    font-size: 27px;
  }
  .features-card-text {
    font-size: 18px;
  }
  .features-heading {
    font-size: 2rem;
  }
}
