.Billing-Video {
  background-color: #d5c7c7;
  width: 89%;
  display: flex;
  align-items: center;
  padding: 4px;
  justify-content: center;
  margin: 0 auto;
  margin-top: 6px;
  border-radius: 3px;
  font-size: 11px;
}
.main-billing {
  width: 100%;
  background-color: aliceblue;
  margin: 0 auto;
  color: black;
  height: auto;
  padding-top: 2px;
  padding-bottom: 40px;
}
.main-billing h1 {
  color: black;
}

.trial-package {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a22336;
  color: #ffffff;
  font-size: 20px;
  width: 88%;
  margin: 20px auto;
  padding: 10px;
  text-transform: uppercase;
}
.subscribe-package {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #219200;
  color: #ffffff;
  font-size: 17px;
  width: 88%;
  margin: 9px auto;
  padding: 10px;
  text-transform: uppercase;
  border: 2px solid #ccc;
}
.year-month {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6dbb5647;
  width: 21%;
  margin: 26px auto;
  padding: 6px;
  border-radius: 11px;
  border: 1px solid black;
}
.package {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 89%;
  margin: 20px auto;
  flex-wrap: wrap;
}

.package-box {
  background-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 90px 160px 0px rgb(64 52 52 / 34%);
  overflow: hidden;
  border-width: 0px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;
  margin: 5px;
  cursor: pointer;
  border: 2px solid #a29affed;
}
.package-box:hover {
}
.package-box ul {
  padding-left: 5px;
}
.package-box ul li {
  padding: 7px;
  font-size: 18px;
}
.package-box h1 {
  margin: 0px;
  padding: 0px;
  width: 100%;
  background-color: bisque;
}
.package-box button {
  padding: 10px;
  width: 80%;
  border: none;
  font-size: 20px;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .package {
    flex-direction: column;
    width: 100%;
    gap: 5px;
    margin: 0px auto;
  }
  .year-month {
    width: 91%;
    border-radius: 3px;
    border: 2px solid #cccccc96;
    margin: 0 auto;
  }
  .package-box {
    width: 96%;
    margin: 0px;
    border-radius: 3px;
  }
  .package-box ul {
    padding-left: 5px;
  }
  .Billing-Video {
    width: 91%;
  }
  .subscribe-package {
    width: 90%;
    text-align: center;
  }
}
